/**
 * Each section of the site has its own module. It probably also has
 * submodules, though this boilerplate is too simple to demonstrate it. Within
 * `src/app/home`, however, could exist several additional folders representing
 * additional modules that would then be listed as dependencies of this one.
 * For example, a `note` section could have the submodules `note.create`,
 * `note.delete`, `note.edit`, etc.
 *
 * Regardless, so long as dependencies are managed correctly, the build process
 * will automatically take take of the rest.
 *
 * The dependencies block here is also where component dependencies should be
 * specified, as shown below.
 */ 
angular.module( 'sq.jobs.forms', [
  'formConfig',

  'sq.jobs.forms.submitForm.component',

  'sq.common.errors.service',

  'sq.user.profile.service',
])

/**
 * Each section or module of the site can also have its own routes. AngularJS
 * will handle ensuring they are all available at run-time, but splitting it
 * this way makes each module more "self-contained".
 */
.config(function config( $routeProvider) {
  $routeProvider.when( '/submit', {
    url: '/submit',
    template: '<sq-job-submit-form></sq-job-submit-form>',
    // form type not working in controller yet
    title: 'Submit',
    secureAsync: true,
    // Angular doesn't seem to 1-way bind resolved properties, so this is 
    // used purely to ensure that by the time our submit form page is loaded
    // we have a profile
    resolve : {
      profile : ($route, userProfile) => userProfile.hasProfile() ? userProfile.user : userProfile.initializeAsync(),
    },
  });

   /*sequenceFile is deprecated, will be merged into listVariants
  $stateProvider.state( 'sequenceFile', {
    url: '/sequenceFile',
    views: {
      "main": {
        controller: 'SequenceFileCtrl as sequenceFile',
        templateUrl: 'forms/sequenceFile_deprecated/sequenceFileForm.tpl.html'
      }
    },
    data:{ pageTitle: 'Sequence File', formType : $stateProvider.state }

    /*
    //how do I DRY with the resolves without nesting views?
    resolve: {

        // A string value resolves to a service
        userAuthService: 'userAuth',

        // A function value resolves to the return
        // value of the function
        userUploadFolder: function(userAuthService)
        {
            return userAuthService.resolveSession();
        }
    }
    */
  /*
    });
  */
});
